import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { TFilter } from 'interfaces/invoice';
import * as S from './styles';
import { usePermissions } from 'hooks';
interface ISearchProps {
  searchFor: TFilter;
  onSearch: Function;
  loading: boolean;
}

const Search: React.FC<ISearchProps> = ({ searchFor, onSearch, loading }) => {
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<TFilter>(searchFor);
  const { hasAnyPermission } = usePermissions();
  const isClientView = hasAnyPermission([
    "Cliente",
    "Customer Service",
    "Exportação",
    "Logística",
  ]);
  const onSubmit = useCallback(
    (data) => {
      onSearch({ search: data.search, searchFor: filter });
    },
    [filter, onSearch]
  );

  return (
    <S.Container>
      <S.Wrapper>
        <S.Button
          onClick={() => setFilter('invoice')}
          active={filter === 'invoice'}
        >
          N&ordm; NF
        </S.Button>
        <S.Button
          onClick={() => setFilter('client')}
          active={filter === 'client'}
        >
          Cliente
        </S.Button>
        <S.Button
          onClick={() => setFilter('product')}
          active={filter === 'product'}
        >
          Produto
        </S.Button>
        <S.Button onClick={() => setFilter('BL')} active={filter === 'BL'}>
          BL
        </S.Button>
        {
          !isClientView &&        <S.Button 
          onClick={() => setFilter('carrier')}
          active={filter === 'carrier'}
        >
          Transportadora
        </S.Button>
        }

      </S.Wrapper>
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.InputWrapper>
          <S.SearchButton>
            {loading ? <S.ActivityIndicatior /> : <S.IconSearch />}
          </S.SearchButton>
          <S.SearchInput name="search" />
        </S.InputWrapper>
      </Form>
    </S.Container>
  );
};

export default Search;
