import { call, put } from 'redux-saga/effects';
import { api, notify } from 'services';
import { requestErrorHandler } from 'utils';

import { AuthActions, LoginRequest } from 'store/ducks/auth';

export const authRehydrateAccessToken = ({ payload }: Record<string, any>) => {
  if (!payload?.auth?.data) return;
  const { accessToken } = payload.auth.data;
  if (accessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
};

export function* authLoginRequest(action: any) {
  try {
    const { data: postData } = action as LoginRequest;
    const { data: responseData } = yield call(api.post, 'auth/login', postData);

    const { accessToken, ...data } = responseData;

    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    yield put(AuthActions.loginSuccess({ ...data, accessToken }));
    notify('success', `Bem-vindo ${data.name}`);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify('error', errorMessage);
    yield put(AuthActions.loginFailure(errorMessage));
  }
}

export function* authLogoutRequest() {
  try {
    notify('success', 'Até mais');
    yield put(AuthActions.logoutSuccess());
  } catch (error) {
    const errorMessage = 'Não é possível deslogar';
    notify('error', errorMessage);
    yield put(AuthActions.loginFailure(errorMessage));
  }
}
