import { IBusinessLine } from './businessLine';
import { ICarrier } from './carrier';
import { IClient } from './client';
import { ICompany } from './company';

export type TFilter = 'invoice' | 'client' | 'product' | 'BL' | 'carrier';

export type IInvoiceStatus =
  | 'transito'
  | 'transito-atraso'
  | 'transito-sem-prazo'
  | 'entregue'
  | 'entregue-cliente'
  | 'entregue-atraso'
  | 'entregue-sem-prazo'
  | 'sem-status'
  | 'cancelada'
  | 'devolucao'
  | 'chegou-cliente';

export enum IInvoiceStatusLabels {
  'transito' = 'Em trânsito',
  'transito-atraso' = 'Trânsito com atraso',
  'transito-sem-prazo' = 'Em trânsito',
  'entregue' = 'Entregue',
  'entregue-cliente' = 'Entregue ao cliente',
  'entregue-atraso' = 'Entregue com atraso',
  'entregue-sem-prazo' = 'Entregue',
  'sem-status' = 'Sem status',
  'cancelada' = 'Cancelada',
  'devolucao' = 'Devolução',
  'chegou-cliente' = 'Chegou no cliente',
}

export interface IListInvoice {
  id: number;
  general_business_line_id: number;
  general_client_id: number;
  ide_serie: number;
  ide_numero_nf: number;
  ide_data_emissao: string;
  dest_nome_municipio: string;
  dest_uf: string;
  total_valor_total_nf: number;
  businessLine: IBusinessLine;
  client: IClient;
}

export interface IInvoiceItem {
  id: number;
  tracking_invoice_id: number;
  nitem_numero_item: number;
  prod_codigo_produto: string;
  prod_descricao_rpoduto: string;
  prod_cfop: number;
  prod_unidade_comercial: string;
  prod_quantidade_comercial: number;
  prod_valor_bruto_comercial: number;
  prod_valor_total_bruto_produtos: number;
  prod_numero_pedido_compra: string;
}

interface IOccurrence {
  id: number;
  tracking_invoice_id: number;
  occurrence_type_id: number;
  invoice_geolocation_id: number;
  created_at: string;
}

interface IGeolocation {
  id: number;
  tracking_invoice_id: number;
  carrier_id: number;
  occurrence_type_id: number;
  latitude: string | null;
  longitude: string | null;
  location_description: string;
  geolocation_date: string;
  delivery_prediction_date: string;
  delivery_date: string;
  vehicle_plate: string;
}

interface ICarrierPrediction {
  id: number;
  tracking_invoice_id: number;
  carrier_id: number;
  invoice_geolocation_id: number;
  delivery_prediction_date: string;
}

export interface IInvoice {
  id: number;
  general_company_id: number;
  general_business_line_id: number;
  general_client_id: number;
  general_carrier_id: number;
  ide_serie: number;
  ide_numero_nf: number;
  ide_data_emissao: string;
  emit_cnpj: string;
  emit_razao_social: string;
  emit_nome_fantasia: string;
  emit_logradouro: string;
  emit_numero: string;
  emit_bairro: string;
  emit_id_municipio_ibge: number;
  emit_nome_municipio: string;
  emit_uf: string;
  emit_cep: string;
  emit_id_pais: string;
  emit_nome_pais: string;
  dest_cnpj: string;
  dest_razao_social: string;
  dest_logradouro: string;
  dest_numero: string;
  dest_bairro: string;
  dest_id_municipio_ibge: number;
  dest_nome_municipio: string;
  dest_uf: string;
  dest_cep: string;
  dest_id_pais: string;
  dest_nome_pais: string;
  entrega_cnpj: string | null;
  entrega_razao_social: string | null;
  entrega_logradouro: string | null;
  entrega_numero: string | null;
  entrega_bairro: string | null;
  entrega_id_municipio_ibge: string | null;
  entrega_nome_municipio: string | null;
  entrega_uf: string | null;
  entrega_cep: string | null;
  entrega_id_pais: number | null;
  entrega_nome_pais: string | null;
  total_valor_total_nf: number;
  transp_modalidade_frete: string;
  transp_cnpj: string;
  transp_razao_social: string;
  transp_inscricao_estadual: string;
  transp_endereco_completo: string;
  transp_nome_municipio: string;
  transp_uf: string;
  transp_peso_liquido_kg: number;
  transp_peso_bruto: number;
  infcpl_informacoes_complementares: string;
  infprot_chave_nf: string;
  latitude: string | null;
  longitude: string | null;
  vehicle_plate: string | null;
  deadline_date: string;
  deadline_date_client: string;
  delivery_date: string;
  distance: number | null;
  duration: number | null;
  router_transport_id: number | null;
  canceled_at: string | null;
  cancellation_justification: string | null;
  picking_id: number | null;
  businessLine: IBusinessLine;
  client: IClient;
  company: ICompany;
  carrier: ICarrier;
  items: IInvoiceItem[];
  occurrences: IOccurrence[];
  geolocations: IGeolocation[];
  carrierPredictions: ICarrierPrediction[];
  status: IInvoiceStatus;
}
