import React, { useCallback } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { AuthActions, AuthState } from 'store/ducks/auth';
import * as S from './styles';

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const { data } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );

  const handleLogout = useCallback(() => {
    dispatch(AuthActions.logoutRequest());
  }, [dispatch]);

  return (
    <S.Container>
      <S.Title>
        Olá,
        <br />
        <span>{data?.name.split(' ')[0]}</span>
      </S.Title>
      <S.LogoutButton onClick={() => handleLogout()}>
        <S.IconLogout />
      </S.LogoutButton>
    </S.Container>
  );
};

export default Header;
